import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _670dce92 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _cf651016 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _5bd34f4a = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _154eee69 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _a203a31c = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _11e2cfea = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _6297e25e = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _aa61f022 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _3c461dbe = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _c8f1f092 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _1c8a5da4 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _9ca35be0 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _3c76ac68 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _795d7394 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _7b9ab3c4 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _3cea8d2e = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _1e7b1526 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _08280d8a = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _45e4ae68 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _3c92454e = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _13ad698b = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _670dce92,
    name: "kits"
  }, {
    path: "/lojas",
    component: _cf651016,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _5bd34f4a,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _154eee69,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _a203a31c,
    name: "motos"
  }, {
    path: "/reset",
    component: _11e2cfea,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _6297e25e,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _aa61f022,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _3c461dbe,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _c8f1f092,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _1c8a5da4,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _9ca35be0,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _3c76ac68,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _795d7394,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _7b9ab3c4,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _3cea8d2e,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _1e7b1526,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _08280d8a,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _45e4ae68,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _3c92454e,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _13ad698b,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
